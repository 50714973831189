import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  baseStyles,
  BORDER_RADIUS,
  CONTENT_MAX_WIDTH,
  DRAWER_WIDTH,
  TOOLBAR_HEIGHT,
  TOOLBAR_HEIGHT_XS,
  withMediaQueries,
  withMultipleStyles
} from "src/styles/Styles";
import Space from "../commons/Space";
import {ColorCard, ColorCardXS, ColorTheme, ColorTitle, ColorWhite} from "src/styles/Colors";
import {AppBar, Box, Button, IconButton, MenuItem, Toolbar, Tooltip} from "@material-ui/core";
import LinkHelper from "../../utils/LinkHelper";
import zIndex from "@material-ui/core/styles/zIndex";
import TextView from "../commons/TextView";
import {get} from "lodash";
import {AppIcon, IconBars, IconMore, IconSetting, IconWallet} from "../commons/AppIcon";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import Utils from "../../utils/utils";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import {ImageIcon, NavActionView, NavBackActionView} from "../commons/ViewHelper";
import LanguageSelector from "../commons/LanguageSelector";
import auth from "src/js/utils/auth";
import {
  getRedirectSearch,
  navBackOrExit,
  PathAboutUsPublic,
  PathCampaigns,
  PathJoin,
  PathLogout,
  PathPlays, PathScreens
} from "src/js/components/routes/NavHelper";
import {FontWeightMedium} from "src/res/FontWeight";

const useStyles = (theme) => ({
  appBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    minHeight: TOOLBAR_HEIGHT,
    height: TOOLBAR_HEIGHT,
    paddingRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: zIndex.drawer + 1,
    [theme.breakpoints.down('xs')]: {
      minHeight: TOOLBAR_HEIGHT_XS,
      height: TOOLBAR_HEIGHT_XS,
    },
  },
  appBarIn: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: TOOLBAR_HEIGHT,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: DRAWER_WIDTH,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: CONTENT_MAX_WIDTH,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      height: TOOLBAR_HEIGHT_XS,
    },
  },
  appBarInReadOnly: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: TOOLBAR_HEIGHT,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: CONTENT_MAX_WIDTH,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      height: TOOLBAR_HEIGHT_XS,
    },
  },
  appHome: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionIcon: {
    border: '2px solid '+ Utils.colorWithAlpha(ColorTheme, 0.1), width: 40, height: 40
  },
  actionIconWithMargin: {
    border: '2px solid '+ Utils.colorWithAlpha(ColorTheme, 0.1), width: 40, height: 40, marginLeft: 16
  },
  moreMenuItemBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 28
  },
});

const DrawerHeaderView = (props) => {
  const {onClick, classes} = props;

  return (
    <Button disabled={true} size={"large"} color={"primary"} onClick={onClick} style={{zIndex: zIndex.drawer + 1}}>
      <div className={classes.appHome}>
        <Space vertical={10} />
        <img src={require('src/images/logo_white.png')} style={{width: 44, height: 44, borderRadius: BORDER_RADIUS, backgroundColor: ColorTheme, padding: 8}} />
        <Space vertical={8} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TextView variant={'h5'} textId={'app.name1'} color={ColorTitle} fontWeight={'bold'} />
        </div>
      </div>
    </Button>
  );
};

class MainHeader extends React.Component {

  constructor(props) {
    super(props);
    this.readOnly = !auth.isAuthenticated();
    this.state = {
      showAccountMenu: false
    }
  }

  closeAccountMenu() {
    this.setState({showAccountMenu: false, accountMenuElement: null});
  }

  openTnc() {
    this.closeAccountMenu();
    LinkHelper.openTnc(this.props.app.defaultLocale);
  }

  logout() {
    this.closeAccountMenu();
    LinkHelper.open(PathLogout);
  }

  render() {
    const {classes} = this.props;
    // const className = !this.readOnly ? classes.appBarIn : classes.appBarInReadOnly;
    const className = classes.appBarIn;
    return (
      <Box component="span" style={{pointerEvents: 'none'}}>
        <AppBar className={classes.appBar} elevation={0} position="fixed" color={'transparent'}>
          <Toolbar className={className}>
            {this.getToolbarView()}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

  getToolbarView = () => {
    const {user, app, classes, isXS, isDrawerHidden} = this.props;
    const isNavPushed = !this.isNavTab();
    const isUserGuest = !user.registerType;
    const showAccount = isDrawerHidden && !isNavPushed && !this.readOnly;

    return (
      <React.Fragment>
        {isDrawerHidden && isNavPushed ?
          <NavBackActionView isLTR={app.isLTR} onClick={this.onBackClicked} style={{pointerEvents: 'auto'}}/>
          : null
        }
        {showAccount ?
          <React.Fragment>
            <IconButton
              id={"buttonAccount"}
              onClick={this.onProfileClicked}
              edge="start"
              style={{position: 'absolute', top: 0, left: 0, right: 0, width: 12, height: '100%', marginLeft: 0, marginRight: 0, borderRadius: 8, padding: 0, pointerEvents: 'auto'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 12, height: '100%', overflow: "hidden"}}>
                <AppIcon icon={IconBars} size={{height: 60}} />
              </div>
            </IconButton>
            <IconButton
              id={"buttonAccount"}
              onClick={this.onProfileClicked}
              edge="start"
              style={{width: 46, height: 46, marginLeft: 15, marginRight: 15, borderRadius: 8, padding: 0, pointerEvents: 'auto'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 46, height: 46, padding: 1, borderRadius: 8, backgroundColor: isXS ? ColorCardXS : ColorCard, overflow: "hidden"}}>
                {!isUserGuest ? <img src={user.imageUrl} width={'100%'} height={'100%'} style={{borderRadius: 8}} />
                  : <AppIcon icon={IconSetting} />
                }
              </div>
            </IconButton>
          </React.Fragment>
          : null
        }
        {/*{isDrawerHidden && !isNavPushed && (isUserGuest || this.readOnly) ?*/}
        {isDrawerHidden && !isNavPushed && this.readOnly ?
          <Button
            disableElevation
            variant={'contained'}
            size={"small"}
            color={'primary'}
            onClick={this.onJoinUsClicked}
            style={{pointerEvents: 'auto', marginLeft: showAccount ? 0 : 15, marginRight: showAccount ? 0 : 15}}>
            <FormattedHTMLMessage id='login.joinRab7Short' />
          </Button>
          : null
        }
        <div className={classes.grow} />

        {/*{isDrawerHidden && this.isUrlCampaigns() && !this.readOnly ?*/}
        {/*  <Tooltip title={<FormattedMessage id={'tooltip.action.more'} />}>*/}
        {/*    <NavActionView isXS={isXS} isLTR={app.isLTR} icon={IconMore} onClick={this.onClickMoreClicked} style={{pointerEvents: 'auto'}}/>*/}
        {/*  </Tooltip>*/}
        {/*  : null*/}
        {/*}*/}

        {isDrawerHidden && this.isUrlCampaigns() && !this.readOnly && !isUserGuest && user.wallet && user.wallet.campaignsPoints > 0 ?
          <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 8, marginRight: 8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: ColorTheme, padding: 6, paddingTop: 2, paddingBottom: 2, borderRadius: 4}}>
              <AppIcon icon={IconWallet} color={ColorWhite} style={{width: 18, height: 18}}/>
              <Space vertical={8}/>
              <TextView color={ColorWhite} fontWeight={FontWeightMedium}>
                {user.wallet.displayCampaignsPoints}
              </TextView>
            </div>
          </div>
          : null
        }
        {isDrawerHidden && !isNavPushed && !this.readOnly && isUserGuest ?
          <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 8, marginRight: 8}}>
            <Button
              disableElevation
              color={'primary'}
              variant={'contained'}
              size={"small"}
              onClick={this.onJoinUsClicked}
              style={{pointerEvents: 'auto'}}>
              <FormattedMessage id='login.joinRab7Short' />
            </Button>
          </div>
          : null
        }
        {!isDrawerHidden && !this.isManageCampaign() ?
          <div style={{borderRadius: BORDER_RADIUS, backgroundColor: ColorCard, marginLeft: !app.isLTR ? 24 : 0, marginRight: app.isLTR ? 24 : 0, pointerEvents: 'auto'}}>
            <LanguageSelector />
          </div>
          : null
        }
        <Menu
          id="menu"
          anchorEl={this.state.showMenuElement}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          keepMounted
          open={this.state.showMenu}
          onClose={this.onCloseMore}>
          <MenuItem onClick={this.onActionRewardClicked}>
            <div className={classes.moreMenuItemBox}>
              <ImageIcon src={require('src/images/ic_menu_rewards_won.png')} />
              <Space verticalSpace={1.2} />
              <Typography color={'textPrimary'} variant={'body2'}>
                <FormattedMessage id={'menu.rewardsWon'} />
              </Typography>
            </div>
          </MenuItem>
          <MenuItem onClick={this.onActionPlayClicked}>
            <div className={classes.moreMenuItemBox}>
              <ImageIcon src={require('src/images/ic_menu_play.png')} />
              <Space verticalSpace={1.2} />
              <Typography color={'textPrimary'} variant={'body2'}>
                <FormattedMessage id={'menu.play'} />
              </Typography>
            </div>
          </MenuItem>
          {/*{this.props.user.bId === 1 || this.props.user.bId === 3 ?*/}
          {/*  <MenuItem onClick={this.onActionGamesClicked}>*/}
          {/*    <div className={classes.moreMenuItemBox}>*/}
          {/*      <ImageIcon src={require('src/images/ic_menu_game.png')} />*/}
          {/*      <Space verticalSpace={1.2} />*/}
          {/*      <Typography color={'textPrimary'} variant={'body2'}>*/}
          {/*        <FormattedMessage id={'menu.games'} />*/}
          {/*      </Typography>*/}
          {/*    </div>*/}
          {/*  </MenuItem>*/}
          {/*  : null*/}
          {/*}*/}
          {/*{this.props.user.bId === 1 || this.props.user.bId === 2 || this.props.user.bId === 3 ?*/}
          {/*  <MenuItem onClick={this.onActionScreensClicked}>*/}
          {/*    <div className={classes.moreMenuItemBox}>*/}
          {/*      <ImageIcon src={require('src/images/ic_menu_digital_display.png')} />*/}
          {/*      <Space verticalSpace={1.2} />*/}
          {/*      <Typography color={'textPrimary'} variant={'body2'}>*/}
          {/*        <FormattedMessage id={'menu.showcases'} />*/}
          {/*      </Typography>*/}
          {/*    </div>*/}
          {/*  </MenuItem>*/}
          {/*  : null*/}
          {/*}*/}
          {/*<MenuItem onClick={this.onActionHowToRedeemOfferStoreClicked}>*/}
          {/*  <div className={classes.moreMenuItemBox}>*/}
          {/*    <ImageIcon src={require('src/images/ic_menu_redeem_offer.png')} />*/}
          {/*    <Space verticalSpace={1.2} />*/}
          {/*    <Typography color={'textPrimary'} variant={'body2'}>*/}
          {/*      <FormattedMessage id={'menu.redeemOfferSteps'} />*/}
          {/*    </Typography>*/}
          {/*  </div>*/}
          {/*</MenuItem>*/}
        </Menu>
      </React.Fragment>
    );
  }

  isUrlPlay() {
    const {history} = this.props;
    const path = get(history, 'location.pathname');
    return path === PathPlays;
  }

  isUrlCampaigns() {
    const {history, isDrawerHidden} = this.props;
    const path = get(history, 'location.pathname');
    return path === PathCampaigns
      || path === '/marketingCampaigns'
      || path === '/loyaltyCampaigns'
      || path === '/educationCampaigns'
      || path === '/trainingCampaigns'
      || path === '/socialCampaigns'
      || path === '/influencerCampaigns';
  }

  isManageCampaign() {
    const {history, isDrawerHidden} = this.props;
    const path = get(history, 'location.pathname');
    return path.startsWith('/campaigns/manage')
      || path.startsWith('/rewards/manage')
      || path.startsWith('/marketingCampaigns/manage')
      || path.startsWith('/loyaltyCampaigns/manage')
      || path.startsWith('/educationCampaigns/manage')
      || path.startsWith('/trainingCampaigns/manage')
      || path.startsWith('/socialCampaigns/manage')
      || path.startsWith('/influencerCampaigns/manage');
  }

  isNavTab() {
    const {history, isDrawerHidden} = this.props;
    const path = get(history, 'location.pathname');
    let isTab = /*path === PathPlays
      ||*/ path === PathCampaigns
      || path === '/marketingCampaigns'
      || path === '/loyaltyCampaigns'
      || path === '/educationCampaigns'
      || path === '/trainingCampaigns'
      || path === '/socialCampaigns'
      || path === '/influencerCampaigns';
    if (!isTab && !isDrawerHidden) {
      isTab = path === '/wons'
        || path === '/rewards'
        || path === '/profile';
    }
    return isTab;
  }

  onClickMoreClicked = (origin) => {
    this.setState({showMenu: true, showMenuElement: origin.target});
  };

  onCloseMore = () => {
    this.setState({showMenu: false, showMenuElement: null});
  };

  onHomeClicked = () => {
    const {history} = this.props;
    history.replace('/');
  };

  onJoinUsClicked = () => {
    this.props.history.push({
      pathname: PathJoin,
      search: getRedirectSearch(this.props.history.location),
    });
  }

  onAboutUsClicked = () => {
    this.props.history.push(PathAboutUsPublic);
  }

  onProfileClicked = () => {
    this.props.history.push('/settings');
  };

  onOpenDrawerClicked = () => {
    this.props.openDrawer();
  };

  onBackClicked = () => {
    navBackOrExit(this.props.history);
  };

  onActionPlayClicked = () => {
    this.props.history.push('/plays');
  };

  onActionGamesClicked = () => {
    this.props.history.push('/games');
  };

  onActionScreensClicked = () => {
    this.props.history.push(PathScreens);
  };

  onActionRewardClicked = () => {
    this.props.history.push('/wons');
  };

  onActionHowToRedeemOfferStoreClicked = () => {
    this.setState({showMenu: false}, () => {
      setTimeout(() => {
        this.props.history.push({
          pathname: '/helpRedeemOffer',
          state: {canGoBack: true}
        });
      });
    });
  };

}

MainHeader.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  openDrawer: PropTypes.func,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.admin.user,
  };
}

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
  ['isDrawerHidden', theme => theme.breakpoints.down('sm'), {defaultMatches: true}],
  ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}]
])(MainHeader)));
